
import { Component, Vue, Prop } from 'vue-property-decorator'

import { SurveyCreator } from 'survey-creator-knockout'
import * as showdown from 'showdown'

import { ISurvey } from '@/interfaces/survey'
import surveyModule from '@/store/survey'

import 'survey-core/defaultV2.css'
import 'survey-creator-core/survey-creator-core.css'

@Component
export default class SurveyCreatorComponent extends Vue {
  creator: any = null
  converter: showdown.Converter | undefined

  @Prop({ required: true })
  survey!: ISurvey

  mounted() {
    this.converter = new showdown.Converter()

    this.creator = new SurveyCreator({
      showLogicTab: false,
      showTranslationTab: false,
      showJSONEditorTab: true,
      // showTestSurveyTab: true,
      showEmbeddedSurveyTab: false,
      isAutoSave: false,
      haveCommercialLicense: true
    })

    //Calls when creator needs to create a new survey instance.
    //In most cases you need to check options.reason for "designer" and "test".
    //Survey is created for designer survey or "Test Survey" tab.
    //Several properties editors create survey as well. In this case the reason will be: "conditionEditor", "defaultValueEditor", "restfulEditor"
    this.creator.onSurveyInstanceCreated.add((sender: any, options: any) => {
      //If we are creating a surface for designer surface
      if (options.reason == 'designer') {
        options.survey.onTextMarkdown.add(this.onTextMarkdown)
      }
      //If we are creating a surface for "Test Survey" tab
      if (options.reason == 'test') {
        options.survey.onTextMarkdown.add(this.onTextMarkdown)
      }
    })

    this.creator.saveSurveyFunc = this.saveSurvey
    this.creator.JSON = this.survey.data_json
    this.creator.toolboxLocation = 'hidden'
    this.creator.render('creatorElement')
  }

  async saveSurvey(saveNo: number, callback: (i: number, status: boolean) => void) {
    console.debug('saveSurvey', saveNo)

    const jsonData = this.creator.JSON
    await surveyModule.actionUpdate(
      {
        ...this.survey,
        title: jsonData.title,
        data_json: jsonData
      },
      callback.bind(this, saveNo)
    )
  }

  onTextMarkdown(survey: any, options: { element: any; text: string; name: string; html: string }) {
    if (options.element.isPage) return

    //convert the markdown text to html
    let str = this.converter!.makeHtml(options.text)
    //remove root paragraphs <p></p>
    str = str.substring(3)
    str = str.substring(0, str.length - 4)
    //set html
    options.html = str
  }
}
