
import { Component, Vue } from 'vue-property-decorator'

import SurveyCreator from '@/components/SurveyCreator.vue'
import surveyModule from '@/store/survey'

@Component({
  components: { SurveyCreator }
})
export default class SurveyEditView extends Vue {
  get survey() {
    return surveyModule.itemById(this.$router.currentRoute.params.id)
  }
}
